
/**
 * Mappings of App IDs to library IDs.
 */
export const AppIdMappings: Record<string, string> = {
  "scope-items": "scope-library"
};

/**
 * Returns the mapped App ID if it exists in AppIdMappings.
 * Falls back to the original appId if no mapping is found.
 *
 * @param appId - The original App ID to look up in the mapping.
 * @returns The mapped App ID or the original value if no mapping is found.
 */
export function getMappedId(appId: string): string {
  return AppIdMappings[appId] ?? appId;
}
